import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import chatify from "../../Assets/Projects/chatify.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Reward Encash"
              description="It is an reward encash app where user can redeem it reward points into thier bank account."
              ghLink="https://github.com/vikas1993/Reward_Encash"
              demoLink="https://play.google.com/apps/test/com.rewardencash/7"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
